

























import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SearchViewModel } from '../../viewmodels/search-viewmodel'

@Observer
@Component({
  components: {
    tag: () => import('../tag.vue'),
  },
})
export default class Tags extends Vue {
  @Inject() vm!: SearchViewModel
}
